@import url(https://fonts.googleapis.com/css?family=Raleway);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media all {
  .page-break {
    display: none;
  }
}
 
@media print {
  html, body {
    height: auto !important;
    height: initial !important;
    overflow: visible !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}
 
@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}
 
@page {
  size: auto;
  margin: 20mm;
}

.logo {
  width: 100px;
  height: 31px;
  /* background: rgba(255, 255, 255, 0.2); */
  background-size: cover;
  background-image: url(/resources/static/media/logo.688d206c.svg);
  /* background-color: rgb(255, 255, 255); */
  background-repeat: no-repeat;
  background-position: center;
  margin: 16px 28px 16px 0;
  float: left;
}

.ant-menu .ant-menu-item-selected {
  background-color: #e6f7ff;
}

.ant-badge-count {
  z-index: auto;
  min-width: 15px;
  height: 15px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 8px;
  box-shadow: 0 0 0 1px #fff;
} 
.ant-badge-status-text{
  font-size: 13px;
}
/* ----------------- */
.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text, .ant-divider-horizontal.ant-divider-with-text-right .ant-divider-inner-text {
  display: inline-block;
  padding: 0 6px;
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 0%;
}

/* ----------------- */
.ant-dropdown-menu-items {
  padding: 0 10px 0 0px;
}

.ant-menu-item-group-title {
  padding: 0px;
}
.login-form {
    max-width: 300px;
    margin: 0 auto!important;
    padding-top: 50px!important;
}
.login-form-forgot {
    float: right;
}
.login-form-button {
    width: 100%;
}
  
.center{
    font-family: 'Raleway', sans-serif;
    background-color: #1abc9c;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card{
    background-color: #fff;
    border-radius: 15px;
    padding: 0.8rem;
}

.card > form{
    display: flex;
    flex-direction: column;
}

.card h1{
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
}

.form-item{
    font-family: 'Raleway', sans-serif;
    padding: 5px;
    margin-bottom: 2rem;
    height: 30px;
    width: 16rem;
    border: 1px solid grey;
}

.form-submit{
    font-family: 'Raleway', sans-serif;
    height: 35px;
    color: #fff;
    background-color: #1abc9c;
    border: none;
    letter-spacing: 0.2rem;
    -webkit-transition: 0.3s opacity ease;
    transition: 0.3s opacity ease;
    cursor: pointer;
}
.form-submit:hover{
   opacity: 0.6;
}

.unknown-avatar {
  width: 46px;
  height: 46px;
  background-size: cover;
  background-image: url(/resources/static/media/unknown.298d9f3e.jpg);
  background-repeat: no-repeat;
  background-position: top center;
}

.logo {
  /* width: 100px; */
  /* height: 31px; */
  /* background: rgba(255, 255, 255, 0.2); */
  background-size: contain;
  background-image: url(/resources/static/media/logo.688d206c.svg);
  /* background-color: rgb(255, 255, 255); */
  background-repeat: no-repeat;
  background-position: left;
  margin: 16px 28px 16px 0;
  float: left;
}

.ant-menu .ant-menu-item-selected {
  background-color: #e6f7ff;
}

.ant-badge-count {
  z-index: auto;
  min-width: 15px;
  height: 15px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 8px;
  box-shadow: 0 0 0 1px #fff;
} 
.ant-badge-status-text{
  font-size: 13px;
}
/* ----------------- */
.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text, .ant-divider-horizontal.ant-divider-with-text-right .ant-divider-inner-text {
  display: inline-block;
  padding: 0 6px;
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 0%;
}

/* ----------------- */
.ant-dropdown-menu-items {
  padding: 0 10px 0 0px;
}

.ant-menu-item-group-title {
  padding: 0px;
}

.my-drawer {
    position: relative;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }
  .my-drawer .am-drawer-sidebar {
    background-color: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .my-drawer.am-drawer .am-drawer-sidebar{
    width: 75%;
  }
  /* .my-drawer .am-drawer-sidebar .am-list {
    width: 300px;
    padding: 0;
  }  */
  .my-drawer .am-drawer-sidebar .am-list .am-list-item .am-list-line .am-list-content {
    font-size: 14px;
  }
  .am-tabs-default-bar-tab-active {
    font-weight: 600;
    /* background-color: whitesmoke; */
    /* background-color: lightcyan; */
    background-color: rgb(224, 241, 255);
    /* color: white; */
  }
  .menu-level-three .am-tabs-default-bar .am-tabs-default-bar-underline {
    border: 1px #001529 solid;
  }
  .menu-level-three .am-tabs-default-bar-tab-active {
    color: #001529;
  }
